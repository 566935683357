export interface SidebarMarkupOptions {
  width?: number;
}
const createSidebarMarkup = (options: SidebarMarkupOptions) => {
  const getStyle = (options: SidebarMarkupOptions) => {
    const { width } = options;
    return width ? `"width: ${width}"` : "";
  };
  return `<div class="media-viewer__sidebar" style=${getStyle(options)}></div>`;
};
export default createSidebarMarkup;
