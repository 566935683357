const createConfig = (layout, position) =>
  layout === 'S' || layout === 'M'
    ? [
        {
          position,
          slot: {
            name: `m.pdp.${position}`,
            sizes: [{ w: 300, h: 250 }],
          },
        },
      ]
    : [
        {
          position,
          slot: {
            name: `pdp.${position}`,
            sizes: [{ w: 300, h: 250 }],
          },
        },
      ];

export const createPhotogalleryConfig = (layout) =>
  createConfig(layout, 'photogallery');
