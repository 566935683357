import Direction from "../direction";
import swipeEventHandler from "./swipeEventHandler";
import slideHtml from "./markup";
import createExternalAdContainer from "../../js/util/media/externalMediaContainer";
import medRecHtml from "./markup/medRecHtml";
import { isMobileDevice } from "../../js/util/mobileDevice";

const isCurrentItemOneBeforeLast = (currentIndex, itemCount) =>
  currentIndex === itemCount - 2;
// Reason why this is greater than or equal to 2 slides before ad call is made is to cater for consumer going backwards and then forwards
// and skipping the slide that's 2 slides before the slide where the ad call is made
const isCurrentItemThreeBeforeLast = (currentIndex, itemCount) =>
  currentIndex >= itemCount - 4 && currentIndex <= itemCount - 2;

const shouldShowAd = (currentIndex, items) =>
  items[currentIndex] && items[currentIndex].mediaType === "advertisement";

const addAdSlide = (items, adHtml = "") => {
  items.push({
    mediaType: "advertisement",
    html: adHtml,
    showCounter: false,
    excludeFromCounter: true,
  });
};

const addVideoPlayedToTheEndListener = (callbacks) => {
  const postMessageListener = (event) => {
    let data;
    try {
      data = JSON.parse(event.data);
    } catch {
      return;
    }

    if (!data) {
      return;
    }

    callbacks.forEach(
      (callback) => typeof callback === "function" && callback(data),
    );
  };

  window.addEventListener("message", postMessageListener, false);

  return postMessageListener;
};

const postMessageIntoIframe = (message, safeframeReference) => {
  const data = { message };
  safeframeReference.contentWindow.postMessage(JSON.stringify(data), "*");
};

const playVideo = (safeframeReference) =>
  postMessageIntoIframe("play_video", safeframeReference);
const pauseVideo = (safeframeReference) =>
  postMessageIntoIframe("pause_video", safeframeReference);

export const adSlideAddon = ({ createVideoSafeframe, loadAdvertisement }) => {
  let pswp;
  let isUserOnAdSlide = false;
  let isAdCallComplete = false;
  let isAdInitialised = false;
  let onBlurToSwitchFocusToParent;

  // Video variables
  let isVideoAd;
  let isVideoAdElementInserted = false;
  let createVideoSafeframePromise;
  let videoAdContainer;
  let videoAdContainerElement;
  let videoPlayedToTheEndListener;
  let videoVisibilityChangeHandler;
  let safeframeReference;

  // MedRec variables
  let isMedRecAd;
  let medRecAdContainer;
  let medRecAdTopElement;

  // Static ad variables
  let slidesContainer;

  const shouldLoadAd = (currentIndex, itemCount, direction) =>
    !isAdCallComplete &&
    itemCount > 2 &&
    isCurrentItemOneBeforeLast(currentIndex, itemCount) &&
    direction === Direction.FORWARD;

  const deleteAdSlide = (items = []) => {
    const mediaItemIndex = items.findIndex(
      (item) => item.mediaType === "advertisement",
    );
    if (mediaItemIndex !== -1) {
      items.splice(mediaItemIndex, 1);
      pswp.invalidateCurrItems();
      pswp.updateSize(true);
    }
  };

  const showAndPlayVideo = () => {
    if (isVideoAd && isUserOnAdSlide && safeframeReference) {
      videoAdContainer.show();
      playVideo(safeframeReference);
    }
  };

  const hideAndPauseVideo = () => {
    videoAdContainer.hide();
    if (isVideoAd && safeframeReference) {
      pauseVideo(safeframeReference);
    }
  };

  const initialiseVideoAd = (items, videoAdElementId, adProperties) => {
    if (!isVideoAd) {
      return;
    }

    videoAdContainerElement &&
      videoAdContainerElement.classList.add("video-safeframe");
    const videoAdElement = document.getElementById(videoAdElementId);
    if (videoAdElement) {
      safeframeReference = videoAdElement
        .getElementsByTagName("iframe")
        .item(0);
    }

    createVideoSafeframePromise.then(() => {
      const data = { message: "init_video", adContentJson: adProperties };
      safeframeReference &&
        safeframeReference.contentWindow.postMessage(JSON.stringify(data), "*");

      if (isUserOnAdSlide) {
        showAndPlayVideo();
      }
    });

    videoVisibilityChangeHandler =
      createIframeVisibilityHandler(safeframeReference);
    document.addEventListener("visibilitychange", videoVisibilityChangeHandler);

    const onVideoPlayedToTheEndCallback = (data) => {
      if (data.message === "videoComplete") {
        pswp.next();
        videoAdContainer.hide();
        deleteAdSlide(items);
        pswp.invalidateCurrItems();
        pswp.updateSize(true);

        window.removeEventListener("message", videoPlayedToTheEndListener);
        window.removeEventListener("blur", onBlurToSwitchFocusToParent);
      }
    };

    const safeframeErrorHandler = (data) => {
      if (data.message === "adLoadError") {
        handleAdFailure(items);
      }
    };

    const swipeActions = {
      right: pswp.prev,
      left: pswp.next,
      up: pswp.close,
      down: pswp.close,
    };

    const { touchstartListener, touchendListener, touchmoveListener } =
      swipeEventHandler(safeframeReference, swipeActions);

    const callbacks = [
      onVideoPlayedToTheEndCallback,
      touchstartListener,
      touchendListener,
      touchmoveListener,
      safeframeErrorHandler,
    ];
    videoPlayedToTheEndListener = addVideoPlayedToTheEndListener(callbacks);
  };

  const initialiseStaticAd = ({ mainPhoto, html, scriptSources = [] }) => {
    pswp.listen("gettingData", (_, item) => {
      if (item.mediaType !== "advertisement") {
        return;
      }

      item.html = slideHtml({ src: mainPhoto && mainPhoto.src, html });
    });

    if (pswp.currItem.mediaType === "advertisement") {
      pswp.invalidateCurrItems();
      pswp.updateSize(true);
    }

    scriptSources.forEach((scriptSource) => {
      const scriptElement = document.createElement("script");
      scriptElement.src = scriptSource;
      slidesContainer.appendChild(scriptElement);
    });

    isAdInitialised = true;
  };

  const initialiseMedRecAd = (renderMedRecAd) => {
    medRecAdContainer = createExternalAdContainer();

    const medRecAd = {
      insertAt: (placeholderId) => {
        const adContainerElement = document.getElementById(placeholderId);

        renderMedRecAd(adContainerElement);
      },
    };

    medRecAdTopElement = medRecAdContainer.insert(medRecAd, medRecHtml);

    if (isMobileDevice()) {
      medRecAdTopElement.addEventListener("scroll", (event) => {
        event.stopPropagation();

        const activationDistance = 50;

        if (
          medRecAdTopElement.scrollLeft >
          medRecAdTopElement.clientWidth + activationDistance
        ) {
          pswp.next();
        } else if (
          medRecAdTopElement.scrollLeft <
          medRecAdTopElement.clientWidth - activationDistance
        ) {
          pswp.prev();
        } else if (
          medRecAdTopElement.scrollTop <
          medRecAdTopElement.clientHeight - activationDistance
        ) {
          pswp.close();
        } else if (
          medRecAdTopElement.scrollTop >
          medRecAdTopElement.clientHeight + activationDistance
        ) {
          pswp.close();
        }
      });
    }
  };

  const handleAdFailure = (items) => {
    if (isUserOnAdSlide) {
      pswp.next();
    }
    deleteAdSlide(items);
  };

  const initialiseVideoAdSafeframe = () => {
    if (isVideoAdElementInserted) {
      return;
    }

    const markupTemplate = (placeholderId) => {
      isVideoAdElementInserted = true;
      return `<div id=${placeholderId}></div>`;
    };
    const externalMedia = {
      insertAt: () => {},
    };

    videoAdContainer = createExternalAdContainer();
    videoAdContainerElement = videoAdContainer.insert(
      externalMedia,
      markupTemplate,
    );

    createVideoSafeframePromise =
      createVideoSafeframe && createVideoSafeframe(videoAdContainerElement);
  };

  const initArrowKeysNavFix = () => {
    // workaround to force blur on Safari
    const forceBlur = (parent) => {
      const input = document.createElement("input");
      input.setAttribute("type", "text");
      parent.insertBefore(input, parent.firstChild);
      input.focus();
      parent.removeChild(input);
    };

    onBlurToSwitchFocusToParent = () => {
      setTimeout(() => {
        if (document.activeElement.tagName === "IFRAME") {
          forceBlur(document.body);
        }
      });
    };

    window.addEventListener("blur", onBlurToSwitchFocusToParent);
  };

  const initialiseAd = (items, mainPhoto) => {
    if (isAdCallComplete) {
      return;
    }

    addAdSlide(items, "");

    const onAdLoad = (adInformation) => {
      const { type } = adInformation;
      isVideoAd = type === "videoAd";
      isMedRecAd = type === "medRecAd";

      try {
        if (isVideoAd) {
          const { videoAdElementId, adProperties } = adInformation;
          initialiseVideoAd(items, videoAdElementId, adProperties);
        } else if (isMedRecAd) {
          const { renderMedRecAd } = adInformation;
          initialiseMedRecAd(renderMedRecAd);
        } else {
          const { html, scriptSources } = adInformation;
          initialiseStaticAd({
            mainPhoto,
            html,
            scriptSources,
          });
        }

        if (isVideoAd || isMedRecAd) {
          initArrowKeysNavFix();
        }
      } catch {
        handleAdFailure(items);
      }
    };

    loadAdvertisement()
      .then(onAdLoad)
      .catch(() => handleAdFailure(items))
      .finally(() => {
        isAdCallComplete = true;
      });
  };

  const createIframeVisibilityHandler = (safeframeReference) => () => {
    if (document.visibilityState === "visible" && isUserOnAdSlide) {
      playVideo(safeframeReference);
    }
  };

  const onEnteringMedRecSlide = () => {
    medRecAdContainer.show();

    if (isMobileDevice()) {
      // Scroll top element to display ad in the center
      medRecAdTopElement.scrollLeft = medRecAdTopElement.clientWidth;
      medRecAdTopElement.scrollTop = medRecAdTopElement.clientHeight;
    }
  };

  const onLeavingMedRecSlide = () => {
    medRecAdContainer.hide();
  };

  const onGallerySlideChange = () => {
    if (!isAdCallComplete) {
      return;
    }

    if (isVideoAd) {
      isUserOnAdSlide ? showAndPlayVideo() : hideAndPauseVideo();
    }

    if (isMedRecAd) {
      isUserOnAdSlide ? onEnteringMedRecSlide() : onLeavingMedRecSlide();
    }
  };

  return {
    init: (_, slidesContainerBox, photoswipe) => {
      slidesContainer = slidesContainerBox;
      pswp = photoswipe;
    },
    beforeChange: ({ direction, currentIndex, items, mainPhoto }) => {
      isUserOnAdSlide = shouldShowAd(currentIndex, items);

      if (
        items.length > 2 &&
        isCurrentItemThreeBeforeLast(currentIndex, items.length) &&
        direction === Direction.FORWARD
      ) {
        initialiseVideoAdSafeframe();
      }

      if (
        !isAdInitialised &&
        shouldLoadAd(currentIndex, items.length, direction)
      ) {
        isAdInitialised = true;
        initialiseAd(items, mainPhoto);
        return;
      }
      onGallerySlideChange();
    },
    destroy: ({ items }) => {
      isAdCallComplete = false;
      deleteAdSlide(items);
      isVideoAdElementInserted = false;
      createVideoSafeframePromise = undefined;

      if (isVideoAd) {
        if (videoPlayedToTheEndListener !== undefined) {
          window.removeEventListener("message", videoPlayedToTheEndListener);
          document.removeEventListener(
            "visibilitychange",
            videoVisibilityChangeHandler,
          );
        }
      }
      if (isVideoAd || isMedRecAd) {
        window.removeEventListener("blur", onBlurToSwitchFocusToParent);
      }

      isAdInitialised = false;
      slidesContainer = null;
      pswp = null;
    },
  };
};
