const medRecHtml = (placeholderId) =>
  `<div class="gallery-medrec-ad">
        <div class="gallery-medrec-ad__aside-wrapper">
            <aside class="gallery-medrec-ad__aside">
                <div class="gallery-medrec-ad__advertisement-bar">ADVERTISEMENT</div>
                <div id="${placeholderId}"></div>
            </aside>
        </div>
    </div>`;

export default medRecHtml;
