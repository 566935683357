const slideHtml = ({ src, html }) => {
  const imageTag = src && `<img class="adslide__img" src="${src}"/>`;

  return `<div class="adslide-container">
            ${imageTag || ""}
            <div class="adslide__overlay"></div>
            <div class="adslide__html">${html}</div>
        </div>`;
};

export default slideHtml;
