import { createElementFromString } from "../../js/util/domUtils";
import { createSidebarMarkup } from "./markup";
import { SidebarMarkupOptions } from "./markup/sidebar";

export interface SidebarAddonOptions {
  initCallback: (sidebar: HTMLDivElement) => void;
  refreshCallback?: (sidebar: HTMLDivElement) => void;
  destroyCallback?: (sidebar: HTMLDivElement) => void;
  options?: SidebarMarkupOptions;
}

export const sidebarAddon = ({
  initCallback,
  refreshCallback,
  destroyCallback,
  options = {},
}: SidebarAddonOptions) => {
  let sidebar: HTMLDivElement | null;
  let timer: string | number | NodeJS.Timeout | undefined;
  let sidebarContentExpired = false;

  const newTimer = () => {
    return setTimeout(() => {
      sidebarContentExpired = true;
    }, 30000);
  };

  return {
    createDOM: ({ container }: { container: HTMLElement }) => {
      const sidebarEl = createElementFromString(createSidebarMarkup(options));
      if (!sidebarEl) {
        throw new Error("Failed to created sidebar element from markup");
      }
      sidebar = sidebarEl as HTMLDivElement;
      container.appendChild(sidebar);
      container.classList.add("media-viewer--with-sidebar");
      container.classList.remove("media-viewer--no-sidebar");
    },

    init: () => {
      if (sidebar) {
        initCallback(sidebar);
      }
      if (refreshCallback) {
        timer = newTimer();
      }
    },

    beforeChange: () => {
      if (sidebarContentExpired) {
        sidebarContentExpired = false;
        if (sidebar && refreshCallback) {
          refreshCallback(sidebar);
        }
        timer = newTimer();
      }
    },

    destroy: () => {
      if (sidebar && destroyCallback) {
        destroyCallback(sidebar);
      }

      if (sidebar && destroyCallback && timer) {
        clearTimeout(timer);
      }
      sidebarContentExpired = false;
      sidebar?.parentNode?.removeChild(sidebar);
      sidebar = null;
    },
  };
};
