const getExternalMediaContainer = () =>
  document.querySelector("#mv-external-media");

const insertExternalMediaMarkup = (externalMediaMarkup: string) => {
  const externalMediaContainer = getExternalMediaContainer();

  if (externalMediaContainer) {
    externalMediaContainer.insertAdjacentHTML("beforeend", externalMediaMarkup);
  }
};

const randomString = () => Math.random().toString(36).slice(2);

export interface ExternalMedia {
  insertAt: (placeholderId: string) => void;
}
export interface ExternalMediaContainer {
  insert: (
    externalMedia: ExternalMedia,
    externalMediaMarkupTemplate: (placeholderId: string) => string,
  ) => HTMLElement;
  show: () => void;
  hide: () => void;
}

export default (): ExternalMediaContainer => {
  let container: HTMLElement;

  return {
    insert: (externalMedia, externalMediaMarkupTemplate) => {
      const placeholderId = randomString();
      insertExternalMediaMarkup(externalMediaMarkupTemplate(placeholderId));
      externalMedia.insertAt(placeholderId);

      const externalMediaContainer = getExternalMediaContainer();

      if (externalMediaContainer && externalMediaContainer.lastElementChild) {
        container = externalMediaContainer.lastElementChild as HTMLElement;
        container.classList.add("mv-media");
      }

      return container;
    },
    show: () => {
      container.classList.add("mv-media--shown");
    },
    hide: () => {
      container.classList.remove("mv-media--shown");
    },
  };
};
