const swipeEventHandler = (element, swipeActions) => {
  let swipeDirection = "none";
  let totalSwipeDistanceX;
  let totalSwipeDistanceY;
  let startTapPositionX;
  let startTapPositionY;

  const handleSwipe = (swipeDirection) => {
    switch (swipeDirection) {
      case "right":
        swipeActions.right && swipeActions.right();
        element.style.removeProperty("transform");
        return;
      case "left":
        swipeActions.left && swipeActions.left();
        element.style.removeProperty("transform");
        return;
      case "up":
        swipeActions.up();
        element.style.removeProperty("transform");
        return;
      case "down":
        swipeActions.down();
        element.style.removeProperty("transform");
        return;
      case "tap":
        totalSwipeDistanceX = 0;
        totalSwipeDistanceY = 0;
        element.style.setProperty("transform", `translate(0,0)`);
        return;
      default:
        element.style.removeProperty("transform");
    }
  };

  const calculateSwipeDirection = (touchObject) => {
    let direction = undefined;
    let swipeThresholdInPixels = 50;
    let restraint = 100;

    totalSwipeDistanceX = touchObject.pageX - startTapPositionX;
    totalSwipeDistanceY = touchObject.pageY - startTapPositionY;
    if (
      Math.abs(totalSwipeDistanceX) >= swipeThresholdInPixels &&
      Math.abs(totalSwipeDistanceY) <= restraint
    ) {
      direction = totalSwipeDistanceX < 0 ? "left" : "right";
    } else if (
      Math.abs(totalSwipeDistanceY) >= swipeThresholdInPixels &&
      Math.abs(totalSwipeDistanceX) <= restraint
    ) {
      direction = totalSwipeDistanceY < 0 ? "up" : "down";
    } else {
      direction = "tap";
    }
    return direction;
  };

  const touchstartListener = (data) => {
    if (data.type !== "touchstart") {
      return;
    }
    const touchObj = data.changedTouches[0];
    swipeDirection = "none";
    totalSwipeDistanceX = 0;
    totalSwipeDistanceY = 0;
    startTapPositionX = touchObj.pageX;
    startTapPositionY = touchObj.pageY;
  };

  const isHorizontalSwipe = (swipeDirection) =>
    swipeDirection === "left" || swipeDirection === "right";
  const isVerticalSwipe = (swipeDirection) =>
    swipeDirection === "up" || swipeDirection === "down";

  const touchendListener = (data) => {
    if (data.type !== "touchend") {
      return;
    }
    const touchObj = data.changedTouches[0];

    const isCurrentDirectionHorizontal = isHorizontalSwipe(swipeDirection);
    const isCurrentDirectionVertical = isVerticalSwipe(swipeDirection);

    const nextSwipeDirection = calculateSwipeDirection(touchObj);

    const isNextDirectionHorizontal = isHorizontalSwipe(nextSwipeDirection);
    const isNextDirectionVertical = isVerticalSwipe(nextSwipeDirection);

    if (
      (isCurrentDirectionHorizontal && isNextDirectionHorizontal) ||
      (isCurrentDirectionVertical && isNextDirectionVertical) ||
      nextSwipeDirection === "tap"
    ) {
      swipeDirection = nextSwipeDirection;
    }

    handleSwipe(swipeDirection);
    swipeDirection = "none";
  };

  const touchmoveListener = (data) => {
    if (data.type !== "touchmove") {
      return;
    }
    const touchObj = data.changedTouches[0];
    const nextSwipeDirection = calculateSwipeDirection(touchObj);

    const hasStartedSwipe =
      swipeDirection === "left" ||
      swipeDirection === "right" ||
      swipeDirection === "up" ||
      swipeDirection === "down";

    swipeDirection = hasStartedSwipe ? swipeDirection : nextSwipeDirection;
    moveElement(touchObj.pageX, touchObj.pageY);
  };

  const moveElement = (currentX, currentY) => {
    const isCurrentDirectionVertical = isVerticalSwipe(swipeDirection);

    const travelledX = currentX - startTapPositionX;
    const travelledY = currentY - startTapPositionY;

    element.style.setProperty("transition", `transform .1s ease-out`);
    if (
      (swipeActions.left && swipeDirection === "left") ||
      (swipeActions.right && swipeDirection === "right")
    ) {
      element.style.setProperty("transform", `translateX(${travelledX}px)`);
    } else if (isCurrentDirectionVertical) {
      element.style.setProperty("transform", `translateY(${travelledY}px)`);
    }
  };

  return {
    touchstartListener,
    touchendListener,
    touchmoveListener,
  };
};

export default swipeEventHandler;
